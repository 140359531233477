import React from 'react'
import './new-footer.less'
import Button from 'components/button/Button'
import YtIcon from '../../images/Icons/yt.svg'
import FacebookIcon from '../../images/Icons/fb.svg'
import InstagramIcon from '../../images/Icons/instagram.svg'
import LinkedInIcon from '../../images/Icons/linkedin.svg'

const socialMediaArrayData = [
  { href: 'https://www.facebook.com/facketDIK/', icon: <FacebookIcon /> },
  { href: 'https://www.instagram.com/facketDIK/', icon: <InstagramIcon /> },
  { href: 'https://www.linkedin.com/company/dik/', icon: <LinkedInIcon /> },
  { href: 'https://www.youtube.com/user/DIKtuben', icon: <YtIcon /> },
]

interface FooterProps {
  heading: string
  buttonHref: string
  buttonText: string
  navigationData: { href: string; label: string }[]
  socialMediaArray?: { href: string; icon: React.ReactNode }[]
}

export const NewFooter = ({
  heading,
  buttonHref,
  buttonText,
  navigationData,
  socialMediaArray = socialMediaArrayData,
}: FooterProps) => {
  return (
    <footer className="footer">
      <div className="Page-container">
        <div className="footer__main-wrapper">
          <header className="footer__header">
            <h5 className="footer__heading">{heading}</h5>
            <Button href={buttonHref} className="button button--rounded-new orange">
              {buttonText}
            </Button>
          </header>
          <div className="footer__content">
            <nav>
              <ul className="footer__nav-list">
                {navigationData.map((item, index) => (
                  <li key={index} className="footer__nav-list-element">
                    <a className="footer__nav-list-element-link" href={item.href}>
                      {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
            <div className="footer__contact-data">
              <div>
                <span className="footer__contact-data-heading">Kontakta oss</span>
                <a className="footer__contact-data-content" href="tel:08-480 040 00">
                  Telefon: 08-480 040 00
                </a>
                <a className="footer__contact-data-content" href="mailto:fraga@dik.se">
                  E-post: fraga@dik.se
                </a>
              </div>
              <div>
                <span className="footer__contact-data-heading">Öppet</span>
                <span className="footer__contact-data-content">Mån-fre: 08:30-12:00</span>
                <span className="footer__contact-data-content">
                  Stängt alla röda dagar och klämdagar
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__social-wrapper">
          <ul className="footer__social-list">
            {socialMediaArray.map((item, index) => (
              <li key={index} className="footer__social-list-element">
                <a target="_blank" href={item.href} className="footer__social-link">
                  {item.icon}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default NewFooter
