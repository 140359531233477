import React from 'react'

export interface DropdownContextValue {
  openDropdownIndex: number | null
  setOpenDropdownIndex: (index: number | null) => void
  setIsAnyDropdownOpen: (isAnyDropdownOpen: boolean) => void
}

export const DropdownContext = React.createContext<DropdownContextValue>({
  openDropdownIndex: null,
  setOpenDropdownIndex: () => {},
  setIsAnyDropdownOpen: () => {},
})

DropdownContext.displayName = 'DropdownContext'
