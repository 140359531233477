import classNames from 'classnames'
import Button from 'components/button/Button'
import { Link } from 'gatsby'
import SearchIcon from 'images/Icons/search-icon.svg'
import { default as React } from 'react'
import { connect } from 'react-redux'
import * as Redux from 'redux'
import { ApplicationState } from 'store'
import { AuthActionTypes, AuthState } from 'store/auth/types'

import './desktop-navigation.less'
import './toolbar.less'

interface StateProps {
  auth: AuthState
}

interface DispatchProps {
  requestSignOut: () => void
}

interface ToolbarProps extends StateProps, DispatchProps {
  variant: 'light' | 'dark'
  isCampaign?: boolean
  children?: React.ReactNode
}

const Toolbar = ({ variant, auth, requestSignOut, isCampaign, children }: ToolbarProps) => {
  const handleSearch = () => {
    const raffleApi = window.raffleApi
    if (!raffleApi){
      return // return early if raffleApi is not available
    }
    const chatElement = document.querySelector('[data-testid="raffle-search-launcher"]')
    if(chatElement!=null){
      const isChatOpen = chatElement.classList.contains('active');
      isChatOpen ? raffleApi.close() : raffleApi.open()
    } else {
      raffleApi.open()
    }
  }

  const isLoggedIn = auth.status === 'LOGGED_IN'
  const isLoggedOut = auth.status === 'UNAUTHORIZED'

  return (
    <div
      className={classNames('mobile-navigation__toolbar-wrapper', {
        'mobile-navigation__toolbar-wrapper--is-campaign': isCampaign,
      })}
    >
      <button
        data-testid="raffle-search-launcher-button"
        className={classNames('mobile-navigation__serach-icon-wrapper', {
          'mobile-navigation__serach-icon-wrapper--hidden': isCampaign,
        })}
        onClick={handleSearch}
      >
        <SearchIcon
          className={classNames('mobile-navigation__search-icon', {
            'mobile-navigation__search-icon--light': variant === 'light',
            'mobile-navigation__search-icon--dark': variant === 'dark',
          })}
        />
      </button>
      {isCampaign ? null : (
        <>
          {auth.status !== 'LOGGED_IN' ? (
            <Link
              className={classNames('mobile-navigation__log-in', {
                'mobile-navigation__log-in--light': variant === 'light',
                'mobile-navigation__log-in--dark': variant === 'dark',
              })}
              to="/logga-in"
            >
              Logga in
            </Link>
          ) : (
            <Link
              className={classNames('mobile-navigation__log-in', {
                'mobile-navigation__log-in--light': variant === 'light',
                'mobile-navigation__log-in--dark': variant === 'dark',
              })}
              onClick={requestSignOut}
              to="/"
            >
              Logga ut
            </Link>
          )}
        </>
      )}
      {isLoggedIn && children}
      <Button href="/bli-medlem/sysselsattning" className="button button--rounded-new orange">
        Bli medlem
      </Button>
    </div>
  )
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return { auth: state.auth }
}

const mapDispatchToProps = (dispatch: Redux.Dispatch): DispatchProps => ({
  requestSignOut: () => dispatch({ type: AuthActionTypes.SIGNOUT_USER }),
})

export default connect<StateProps, DispatchProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps,
)(Toolbar)
