import { connect } from 'react-redux'
import * as Redux from 'redux'
import { ApplicationState } from 'store'
import { AuthActionTypes, AuthState } from 'store/auth/types'
import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { TogglableTopLink } from './TogglableTopLink'
import './navbar.less'
import CloseIcon from 'images/Icons/close-menu.svg'
import HamburgerIcon from 'images/Icons/hamburger.svg'
import Logo from 'images/Icons/new-logo.svg'
import { NavbarProps } from './Navbar'
import Toolbar from './Toolbar'
import './mobile-navigation.less'
import _uniqueId from 'lodash/uniqueId'

interface StateProps {
  auth: AuthState
}

interface DispatchProps {
  requestSignOut: () => void
}

interface Props extends StateProps, DispatchProps, NavbarProps {}

export const MobileNavigation = ({ links, isCampaign, auth }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [openIndex, setOpenIndex] = useState(-1)

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
  }

  useEffect(() => {
    const htmlElement = document.documentElement
    if (isOpen) {
      htmlElement.style.overflow = 'hidden'
    } else {
      htmlElement.style.overflow = 'unset'
    }

    return () => {
      htmlElement.style.overflow = 'unset'
    }
  }, [isOpen])

  const handleToggleOpen = (index: number) => {
    setOpenIndex(index === openIndex ? -1 : index)
  }

  const isLoggedIn = auth.status === 'LOGGED_IN'

  return (
    <div className="mobile-navigation-wrapper">
      <div className="Page-container">
        <div className="mobile-navigation">
          <div className="mobile-navigation__header">
            <Link to="/" className="mobile-navigation__logo-link">
              <Logo className="mobile-navigation__logo" />
            </Link>
            <button className="mobile-navigation__button" onClick={toggleDrawer}>
              <HamburgerIcon className="mobile-navigation__hamburger-icon" />
            </button>
          </div>
        </div>
        <Drawer
          lockBackgroundScroll
          open={isOpen}
          onClose={toggleDrawer}
          direction="right"
          size="85%"
          className=""
          style={{
            maxWidth: '450px',
          }}
          zIndex={9}
        >
          <div
            style={{
              height: '100%',
              backgroundColor: '#3D53A8',
              overflowY: 'scroll',
            }}
          >
            <div className="mobile-navigation__close-button-wrapper">
              <button onClick={toggleDrawer} className="mobile-navigation__close-button">
                <CloseIcon className="mobile-navigation__icon" />
              </button>
            </div>
            <div
              style={{
                padding: '0 24px',
              }}
            >
              <Toolbar variant={isOpen ? 'dark' : 'light'} isCampaign={isCampaign} />
            </div>
            {!isCampaign && (
              <div className="mobile-navigation__categories-wrapper">
                {isLoggedIn && (
                  <TogglableTopLink
                    key={_uniqueId('mobile-navigation__top-link-')}
                    isOpen={openIndex === 99}
                    onToggleOpen={() => handleToggleOpen(99)}
                    heading="Mina sidor"
                    className="mobile-navigation__top-link"
                    submenuList={[
                      {
                        labelUrl: '/mina-sidor',
                        label: {
                          label: 'Mina sidor',
                        },
                        linksList: [
                          {
                            label: 'Aktuellt',
                            href: '/mina-sidor',
                          },
                          {
                            label: 'Mina uppgifter',
                            href: '/mina-sidor/uppgifter',
                          },
                          {
                            label: 'Mina förmåner',
                            href: '/mina-sidor/formaner',
                          },
                          {
                            label: 'Mina fakturor',
                            href: '/mina-sidor/fakturor',
                          },
                          {
                            label: 'Saco lönesök',
                            href: '/sacolonesokredirect',
                          },
                        ],
                      },
                    ]}
                    isToggleable={true}
                  />
                )}
                {links.map((link, index) => {
                  const isToggleable = 'submenuList' in link

                  if (isToggleable) {
                    return (
                      <TogglableTopLink
                        key={_uniqueId('mobile-navigation__top-link-')}
                        isOpen={openIndex === index}
                        onToggleOpen={() => handleToggleOpen(index)}
                        heading={link.label.label}
                        className="mobile-navigation__top-link"
                        submenuList={isToggleable ? link.submenuList : null}
                        isToggleable={isToggleable}
                      />
                    )
                  }

                  return (
                    <TogglableTopLink
                      key={_uniqueId('mobile-navigation__top-link-')}
                      hrefHeading={link.href}
                      isOpen={openIndex === index}
                      onToggleOpen={() => handleToggleOpen(index)}
                      heading={link.labelFlat}
                      submenuList={null}
                      isToggleable={false}
                      className="mobile-navigation__top-link"
                    />
                  )
                })}
              </div>
            )}
          </div>
        </Drawer>
      </div>
    </div>
  )
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return { auth: state.auth }
}

const mapDispatchToProps = (dispatch: Redux.Dispatch): DispatchProps => ({
  requestSignOut: () => dispatch({ type: AuthActionTypes.SIGNOUT_USER }),
})

export default connect<StateProps, DispatchProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps,
)(MobileNavigation)
