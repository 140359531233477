import React from 'react'
import classNames from 'classnames'
import './desktop-navigation-item.less'
import { DropdownContext } from 'components/Dropdown/context'
import { Link } from 'gatsby'

interface DesktopNavigationItemProps {
  label: string
  variant: 'light' | 'dark'
  href: string
}

export const DesktopNavigationItem = ({ label, href, variant }: DesktopNavigationItemProps) => {
  const { setOpenDropdownIndex, setIsAnyDropdownOpen } = React.useContext(DropdownContext)

  return (
    <Link to={href}>
      <div
        className="desktop-navigation-item"
        onMouseOver={() => {
          setOpenDropdownIndex(null)
          setIsAnyDropdownOpen(false)
        }}
      >
        <span
          className={classNames('desktop-navigation-item__label', {
            'desktop-navigation-item__label--light': variant === 'light',
            'desktop-navigation-item__label--dark': variant === 'dark',
          })}
        >
          {label}
        </span>
      </div>
    </Link>
  )
}
