import React from 'react'
import { NavbarCategory, NavbarCategoryLink } from './NavbarCategory'
import './navbar-category-wrapper.less'
import classNames from 'classnames'

export interface NavbarCategoryWrapperProps {
  submenuList: {
    labelUrl?: string
    label: {
      label: string
    }
    linksList: NavbarCategoryLink[]
  }[]
  variant?: 'light' | 'dark'
  isDesktop?: boolean
}

export const NavbarCategoryWrapper = ({
  submenuList,
  variant = 'light',
  isDesktop = false,
}: NavbarCategoryWrapperProps) => {
  return (
    <div
      className={classNames('navbar-category-wrapper', {
        'navbar-category-wrapper--desktop': isDesktop,
      })}
    >
      {submenuList.map((submenu, index) => (
        <NavbarCategory
          key={index}
          className="navbar-category-wrapper__item"
          title={submenu.label.label}
          variant={variant}
          links={submenu.linksList}
          labelHref={submenu.labelUrl}
        />
      ))}
    </div>
  )
}
