import React from 'react'
import classNames from 'classnames'
import './dropdown-overlay.less'

interface DropdownOverlayProps {
  isOpen: boolean
  onMouseEnter?: () => void
  onMouseDown?: () => void
  isGlobalAnnouncement?: boolean
  topOffset?: number
}

export const DropdownOverlay = ({
  isOpen = false,
  onMouseEnter,
  onMouseDown,
  isGlobalAnnouncement,
  topOffset = 0,
}: DropdownOverlayProps) => {
  // 70px is a fixed height of the global navigation topbar, topOffset is a height of global annoucement component, the height is determinted by how much text is in the component
  const topOffsetToSet = 70 + topOffset

  return (
    <div
      style={{
        top: isGlobalAnnouncement ? topOffsetToSet : undefined,
      }}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      className={classNames('dropdown-overlay', {
        'dropdown-overlay--open': isOpen,
        'dropdown-overlay--global-announcement': isGlobalAnnouncement,
      })}
    />
  )
}
