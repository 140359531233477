import React from 'react'
import { DropdownItem } from './DropdownItem'
import { DropdownRoot } from './DropdownRoot'

interface DropdownProps {
  children?: React.ReactNode
}

export const Dropdown = ({ children }: DropdownProps) => children

Dropdown.displayName = 'Dropdown'
Dropdown.Root = DropdownRoot
Dropdown.Item = DropdownItem
