import ChevronIcon from 'images/Icons/chevron.svg'
import React from 'react'
import './desktop-navigation-trigger.less'
import classNames from 'classnames'
import { DropdownContext } from 'components/Dropdown/context'
import { Link } from 'gatsby'

interface DesktopNavigationTriggerProps {
  label: string
  variant: 'dark' | 'light'
  withChevron?: boolean
}

export const DesktopNavigationTrigger = React.forwardRef<
  HTMLDivElement,
  DesktopNavigationTriggerProps
>(({ label, variant, withChevron = false, ...props }, ref) => {
  const dropdownIndex = React.useRef<number | null>(null) as React.MutableRefObject<number | null>

  const { setOpenDropdownIndex } = React.useContext(DropdownContext)

  const handleDropdownOpen = () => {
    if (dropdownIndex.current !== null) {
      setOpenDropdownIndex(dropdownIndex.current)
    }
  }

  const handleTriggerRef = (node: HTMLDivElement) => {
    if (node) {
      const dropdownItem = node.closest('.desktop-navigation__item')

      if (dropdownItem) {
        dropdownIndex.current = Array.from(dropdownItem.parentNode.children).indexOf(dropdownItem)
      }
    }

    if (ref) {
      if (typeof ref === 'function') {
        ref(node)
      } else {
        ref.current = node
      }
    }
  }

  return (
    <div
      className="desktop-navigation-trigger"
      onMouseOver={handleDropdownOpen}
      ref={handleTriggerRef}
      {...props}
    >
      {withChevron && (
        <span
          className={classNames('desktop-navigation-trigger__label', {
            'desktop-navigation-trigger__label--light': variant === 'light',
            'desktop-navigation-trigger__label--dark': variant === 'dark',
          })}
        >
          {label}
        </span>
      )}
      {!withChevron && (
        <Link to="/medlemskap" className="">
          <span
            className={classNames('desktop-navigation-trigger__label', {
              'desktop-navigation-trigger__label--light': variant === 'light',
              'desktop-navigation-trigger__label--dark': variant === 'dark',
            })}
          >
            {label}
          </span>
        </Link>
      )}
      {withChevron && (
        <ChevronIcon
          className={classNames('desktop-navigation-trigger__chevron-icon', {
            'desktop-navigation-trigger__chevron-icon--light': variant === 'light',
            'desktop-navigation-trigger__chevron-icon--dark': variant === 'dark',
          })}
        />
      )}
    </div>
  )
})
