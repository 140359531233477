import React, { useState } from 'react'
import { NavbarCategoryWrapper, dummyCategoriesArray } from './NavbarCategoryWrapper'
import ChevronIcon from 'images/Icons/chevron.svg'
import './togglable-top-link.less'
import classNames from 'classnames'
import { Link } from 'gatsby'

interface TogglableTopLinkPropsBase {
  className?: string
  heading?: string
  isOpen?: boolean
  onToggleOpen?: () => void
}

interface TogglableTopLinkToggleableProps extends TogglableTopLinkPropsBase {
  isToggleable: true
  submenuList: any
  hrefHeading?: string
}

interface TogglableTopLinkNonToggleableProps extends TogglableTopLinkPropsBase {
  isToggleable: false
  submenuList: any[] | null
  hrefHeading: string
}

type TogglableTopLinkProps = TogglableTopLinkToggleableProps | TogglableTopLinkNonToggleableProps

export const TogglableTopLink = ({
  className,
  heading,
  submenuList,
  isOpen,
  onToggleOpen,
  isToggleable,
  hrefHeading,
}: TogglableTopLinkProps) => {
  const iconStyle = {
    transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
  }

  const handleClick = () => {
    if (isToggleable && onToggleOpen) {
      onToggleOpen()
    }
  }

  const content = !isToggleable ? (
    <Link
      to={hrefHeading}
      className={classNames('togglable-top-link__text', {
        'togglable-top-link__text--opened': isOpen,
      })}
    >
      {heading}
    </Link>
  ) : (
    <span
      className={classNames('togglable-top-link__text', {
        'togglable-top-link__text--opened': isOpen,
      })}
    >
      {heading}
    </span>
  )

  return (
    <div className={classNames('togglable-top-link', className)}>
      <div
        className={classNames('togglable-top-link__content', {
          'togglable-top-link__content--closed': !isOpen,
        })}
        onClick={handleClick}
      >
        {content}
        {isToggleable && (
          <ChevronIcon
            className={classNames('togglable-top-link__icon', {
              'togglable-top-link__icon--rotated': isOpen,
            })}
            style={iconStyle}
          />
        )}
      </div>
      {submenuList && (
        <div
          className={classNames('togglable-top-link__category-wrapper', {
            'togglable-top-link__category-wrapper--closed': isOpen,
          })}
        >
          <NavbarCategoryWrapper submenuList={submenuList} variant="light" />
        </div>
      )}
    </div>
  )
}
