import { Navbar } from 'components/Navigation/Navbar'
import NewFooter from 'components/NewFooter/NewFooter'
import Notification from 'components/Notification/notification'
import { StaticQuery, graphql } from 'gatsby'
import React, { PropsWithChildren, createContext, useEffect, useRef, useState } from 'react'
import '../Framework/app.less'
import classNames from 'classnames'

const query = graphql`
  query {
    allContentfulNewFooter {
      edges {
        node {
          headline {
            headline
          }
          ctaButton {
            href
            label
          }
          links {
            ... on ContentfulNewLink {
              label
              href
            }
          }
        }
      }
    }
    allContentfulNewMenu {
      edges {
        node {
          links {
            ... on ContentfulNewLink {
              labelFlat: label
              href
            }
            ... on ContentfulTopLinkWithSubmenu {
              featuredCard {
                ... on ContentfulTopLinkFeaturedCard {
                  image {
                    description
                    file {
                      url
                    }
                  }
                  description {
                    description
                  }
                  ctaLink {
                    ... on ContentfulNewLink {
                      label
                      href
                    }
                  }
                }
              }
              label {
                label
              }
              submenuList {
                ... on ContentfulSubmenu {
                  labelUrl
                  label {
                    label
                  }
                  linksList {
                    ... on ContentfulNewLink {
                      label
                      href
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    contentfulStartPage {
      globalAnnouncement {
        globalAnnouncement
      }
    }
    allContentfulNewHomePage {
      edges {
        node {
          globalAnnouncement {
            globalAnnouncement
          }
        }
      }
    }
    allContentfulPageStructure {
      edges {
        node {
          siteTree4NavigationName
          siteTree4Slug
          siteTree4PageStructure {
            ... on ContentfulArticleListPage {
              slug
              navigationName
            }
            ... on ContentfulArticle {
              slug
              navigationName
            }
            ... on ContentfulCampaignPage {
              slug
              heading
            }
          }
          siteTree3NavigationName
          siteTree3Slug
          siteTree3PageStructure {
            ... on ContentfulArticleListPage {
              slug
              navigationName
            }
            ... on ContentfulArticle {
              slug
              navigationName
            }
            ... on ContentfulCampaignPage {
              slug
              heading
            }
          }
          siteTree2NavigationName
          siteTree2Slug
          siteTree2PageStructure {
            ... on ContentfulArticleListPage {
              slug
              navigationName
            }
            ... on ContentfulArticle {
              slug
              navigationName
            }
            ... on ContentfulCampaignPage {
              slug
              heading
            }
          }
          siteTree1NavigationName
          siteTree1Slug
          siteTree1PageStructure {
            ... on ContentfulArticleListPage {
              slug
              navigationName
            }
            ... on ContentfulArticle {
              slug
              navigationName
            }
            ... on ContentfulCampaignPage {
              slug
              heading
            }
          }
        }
      }
    }
    allSitePage {
      edges {
        node {
          path
          context {
            slug
          }
        }
      }
    }
  }
`

export const BannerHeightContext = createContext({
  bannerHeight: 0,
  setBannerHeight: (height: number) => {},
})

BannerHeightContext.displayName = 'BannerHeightContext'

const LayoutNew = ({
  children,
  hasInvoices = false,
  campaignHeader = false,
  headerCssClass = '',
  forceWhite = false,
}: {
  children: React.ReactNode
  hasInvoices?: boolean
  campaignHeader?: boolean
  headerCssClass?: string
  forceWhite?: boolean
}) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => {
        const globalAnnouncement =
          data.allContentfulNewHomePage?.edges[0]?.node?.globalAnnouncement?.globalAnnouncement?.trim()
        const dataNodes = data.allContentfulNewMenu.edges[0].node
        const footerDataNodes = data.allContentfulNewFooter.edges[0].node
        const [bannerHeight, setBannerHeight] = useState(0)

        const Wrapper = globalAnnouncement
          ? ({ children }: PropsWithChildren<{}>) => {
              const bannerElement = useRef<HTMLDivElement>(null)

              useEffect(() => {
                if (bannerElement.current) {
                  setBannerHeight(bannerElement.current.offsetHeight)
                }
              }, [bannerElement.current])

              return (
                <BannerHeightContext.Provider value={{ bannerHeight, setBannerHeight }}>
                  <Notification
                    ref={bannerElement}
                    fade
                    message={globalAnnouncement}
                    theme="error"
                    position="top"
                    fadeDuration={0}
                  />
                  <div style={{ marginTop: bannerHeight }}>{children}</div>
                </BannerHeightContext.Provider>
              )
            }
          : React.Fragment

        const isArticleClassPresent =
          Array.isArray(children) &&
          children.length >= 3 &&
          children[2]?.props?.className?.includes('article')

        return (
          <Wrapper>
            <div
              className={isArticleClassPresent ? 'u-colorBgWhite' : ''}
              style={{
                backgroundSize: 'auto 810px',
                backgroundRepeat: 'repeat-x',
              }}
            >
              <Navbar
                isGlobalAnnouncement={Boolean(globalAnnouncement)}
                style={{
                  top: globalAnnouncement ? `${bannerHeight}px` : 0,
                }}
                links={dataNodes.links}
                isCampaign={campaignHeader}
              />
              <div
                className={classNames('Page-main layout-new-main', {
                  'layout-new-main--is-global-annoucement': globalAnnouncement,
                })}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: `100vh`,
                }}
              >
                <main
                  className="u-flex u-flexDirectionColumn"
                  style={{ flexGrow: 1, justifyContent: 'center' }}
                >
                  {children}
                </main>
                <NewFooter
                  heading={footerDataNodes.headline.headline}
                  buttonText={footerDataNodes.ctaButton.label}
                  buttonHref={footerDataNodes.ctaButton.href}
                  navigationData={footerDataNodes.links}
                />
              </div>
            </div>
          </Wrapper>
        )
      }}
    />
  )
}

export default LayoutNew
