import React, { useContext, useEffect, useState } from 'react'
import { DropdownOverlay } from './DropdownOverlay'
import { DropdownContext, DropdownContextValue } from './context'
import { BannerHeightContext } from 'layout/LayoutNew'

interface DropdownRootProps {
  onOpen?: () => void
  onClose?: () => void
  children: React.ReactNode
  isGlobalAnnouncement?: boolean
}

export const DropdownRoot: React.FC<DropdownRootProps> = ({
  children,
  onOpen,
  onClose,
  isGlobalAnnouncement,
}: DropdownRootProps) => {
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null)
  const [isAnyDropdownOpen, setIsAnyDropdownOpen] = useState(false)
  const { bannerHeight } = useContext(BannerHeightContext)

  const contextValue: DropdownContextValue = {
    openDropdownIndex,
    setOpenDropdownIndex,
    setIsAnyDropdownOpen,
  }

  useEffect(() => {
    if (openDropdownIndex !== null && onOpen) {
      onOpen()
    }

    if (openDropdownIndex === null && onClose) {
      onClose()
    }
  }, [openDropdownIndex, onOpen, onClose])

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null

    if (!isAnyDropdownOpen) {
      timeout = setTimeout(() => {
        setOpenDropdownIndex(null)
      }, 300)
    }

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [isAnyDropdownOpen])

  return (
    <DropdownContext.Provider value={contextValue}>
      {children}

      <DropdownOverlay
        isGlobalAnnouncement={isGlobalAnnouncement}
        onMouseEnter={() => setIsAnyDropdownOpen(false)}
        isOpen={isAnyDropdownOpen}
        topOffset={bannerHeight}
      />
    </DropdownContext.Provider>
  )
}
