import { Link } from 'gatsby'
import React from 'react'
import './navbar-category.less'
import classNames from 'classnames'
import _uniqueId from 'lodash/uniqueId'

export interface NavbarCategoryLink {
  href: string
  label: string
}

interface NavbarCategoryProps {
  title: string
  links: NavbarCategoryLink[]
  className?: string
  variant?: 'dark' | 'light'
  labelHref?: string
}

export const NavbarCategory = ({
  title,
  className,
  links,
  variant = 'light',
  labelHref,
}: NavbarCategoryProps) => {
  return (
    <div className={classNames('navbar-category', className)}>
      <h5
        className={classNames('navbar-category__title', {
          'navbar-category__title--dark': variant === 'dark',
          'navbar-category__title--light': variant === 'light',
        })}
      >
        {labelHref ? (
          <Link
            className={classNames('navbar-category__title-link', {
              'navbar-category__title-link--dark': variant === 'dark',
              'navbar-category__title-link--light': variant === 'light',
            })}
            to={labelHref}
          >
            {title}
          </Link>
        ) : (
          <span
            className={classNames('navbar-category__title-link', {
              'navbar-category__title-link--dark': variant === 'dark',
              'navbar-category__title-link--light': variant === 'light',
            })}
          >
            {title}
          </span>
        )}
      </h5>
      <ul className="navbar-category__list">
        {links.map((link, index) => (
          <li className="navbar-category__item" key={_uniqueId('category-item-')}>
            <Link
              className={classNames('navbar-category__link', {
                'navbar-category__link--dark': variant === 'dark',
                'navbar-category__link--light': variant === 'light',
              })}
              to={link.href}
              key={index}
            >
              {link.label}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
