import classNames from 'classnames'
import { Link } from 'gatsby'
import React from 'react'

export interface FeaturedCard {
  image: {
    description: string
    file: {
      url: string
    }
  }
  description: {
    description: string
  }
  ctaLink: {
    label: string
    href: string
  }
}

interface NavigationFeaturedCardProps {
  title: string
  variant?: 'dark' | 'light'
  description: string
  ctaLabel: string
  ctaHref: string
  imgSrc: string
  imgAlt: string
}

export const NavigationFeaturedCard = ({
  title,
  variant,
  ctaHref,
  ctaLabel,
  description,
  imgSrc,
  imgAlt,
}: NavigationFeaturedCardProps) => {
  return (
    <div className="dropdown-container__featured">
      <h5
        className={classNames('dropdown-container__featured-title', {
          'dropdown-container__featured-title--dark': variant === 'dark',
          'dropdown-container__featured-title--light': variant === 'light',
        })}
      >
        {title}
      </h5>
      <img src={imgSrc} alt={imgAlt} className="dropdown-container__featured-image" />
      <p
        className={classNames('dropdown-container__featured-text', {
          'dropdown-container__featured-text--dark': variant === 'dark',
          'dropdown-container__featured-text--light': variant === 'light',
        })}
      >
        {description}
      </p>

      <Link
        className={classNames('dropdown-container__featured-link', {
          'dropdown-container__featured-link--dark': variant === 'dark',
          'dropdown-container__featured-link--light': variant === 'light',
        })}
        to={ctaHref}
      >
        {ctaLabel}
      </Link>
    </div>
  )
}
