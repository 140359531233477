import classNames from 'classnames'
import Dropdown from 'components/Dropdown'
import { Link } from 'gatsby'
import DikLogo from 'images/Icons/new-logo.svg'
import _debounce from 'lodash/debounce'
import React, { useEffect, useRef } from 'react'
import { DesktopNavigationItem } from './DesktopNavigationItem'
import { DesktopNavigationTrigger } from './DesktopNavigationTrigger'
import { DropdownContainer } from './DropdownContainer'
import { NavbarProps } from './Navbar'
import Toolbar from './Toolbar'
import './desktop-navigation.less'

export const DesktopNavigation = ({ links, isGlobalAnnouncement, isCampaign }: NavbarProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false)
  const closeTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const [isScrolled, setIsScrolled] = React.useState(false)
  const [variant, setVariant] = React.useState<'dark' | 'light'>('dark')

  useEffect(() => {
    return () => {
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current)
      }
    }
  }, [])

  useEffect(() => {
    const handleScroll = _debounce(() => {
      const isNotAtTop = window.scrollY > 0
      setIsScrolled(isNotAtTop)
    }, 200)

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      handleScroll.cancel() // Cancel any pending execution of the debounced function
    }
  }, [])

  // if dropdown is opened change the variant to light
  useEffect(() => {
    if (isDropdownOpen) {
      setVariant('dark')
    } else {
      setVariant('light')
    }
  }, [isDropdownOpen])

  return (
    <nav
      className={classNames('desktop-navigation', {
        'desktop-navigation--light': variant === 'light',
        'desktop-navigation--dark': variant === 'dark',
      })}
    >
      <div
        className={classNames('desktop-navigation__wrapper', {
          'desktop-navigation__wrapper--is-campaign': isCampaign,
        })}
      >
        <div className="placeholder_dont-remove" />
        <div
          className={classNames('desktop-navigation__logo', {
            'desktop-navigation__logo--scrolled': isScrolled,
          })}
        >
          <Link to="/" className="desktop-navigation__logo-link">
            <DikLogo
              className={classNames('desktop-navigation__logo-image', {
                'desktop-navigation__logo-image--open': isDropdownOpen,
                'desktop-navigation__logo-image--scrolled': isScrolled,
                'desktop-navigation__logo-image--light': variant === 'light',
                'desktop-navigation__logo-image--dark': variant === 'dark',
              })}
            />
          </Link>
        </div>
        <Dropdown.Root
          isGlobalAnnouncement={isGlobalAnnouncement}
          onOpen={() => {
            setIsDropdownOpen(true)
            if (closeTimeoutRef.current) {
              clearTimeout(closeTimeoutRef.current)
            }
          }}
          onClose={() => {
            closeTimeoutRef.current = setTimeout(() => {
              setIsDropdownOpen(false)
            }, 500)
          }}
        >
          {!isCampaign && (
            <div className="desktop-navigation__nav-wrapper">
              <ul className="desktop-navigation__list">
                {links.map((link, index) => {
                  const isDropdown = 'submenuList' in link

                  if (isDropdown) {
                    return (
                      <li className="desktop-navigation__item" key={index}>
                        <Dropdown.Item
                          onClose={() => setIsDropdownOpen(false)}
                          onOpen={() => setIsDropdownOpen(true)}
                          index={index}
                          trigger={
                            <DesktopNavigationTrigger
                              label={link.label.label}
                              variant={variant}
                              withChevron
                            />
                          }
                          content={
                            <DropdownContainer
                              title={link.label.label}
                              variant={variant}
                              submenuList={link.submenuList}
                              featuredCard={link.featuredCard}
                            />
                          }
                        />
                      </li>
                    )
                  }

                  return (
                    <li className="desktop-navigation__item" key={index}>
                      <DesktopNavigationItem
                        label={link.labelFlat}
                        variant={variant}
                        href={link.href}
                      />
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
          <div className="desktop-navigation__toolbar-wrapper">
            <Toolbar variant={variant} isCampaign={isCampaign}>
              <Dropdown.Item
                onClose={() => setIsDropdownOpen(false)}
                onOpen={() => setIsDropdownOpen(true)}
                index={19}
                trigger={
                  <DesktopNavigationTrigger label="Mina sidor" variant={variant} withChevron />
                }
                content={
                  <DropdownContainer
                    title={'Mina Sidor'}
                    variant={variant}
                    submenuList={[
                      {
                        labelUrl: '/mina-sidor',
                        label: {
                          label: 'Mina sidor',
                        },
                        linksList: [
                          {
                            label: 'Aktuellt',
                            href: '/mina-sidor',
                          },
                          {
                            label: 'Mina uppgifter',
                            href: '/mina-sidor/uppgifter',
                          },
                          {
                            label: 'Mina förmåner',
                            href: '/mina-sidor/formaner',
                          },
                          {
                            label: 'Mina fakturor',
                            href: '/mina-sidor/fakturor',
                          },
                          {
                            label: 'Saco lönesök',
                            href: '/sacolonesokredirect',
                          },
                        ],
                      },
                    ]}
                  />
                }
              />
            </Toolbar>
          </div>
        </Dropdown.Root>
      </div>
    </nav>
  )
}
