import * as React from "react";
import posed from "react-pose";
import { capitalize } from "lodash";
import { forwardRef } from "react";

interface Props {
  fade: boolean;
  message: string;
  theme?: "success" | "warning" | "error";
  children?: React.ReactNode;
  position?: "top" | "bottom";
  fadeDuration?: number;
}

const Notification = ({
  position = "bottom",
  fadeDuration = 500,
  ...props
}: Props & { innerRef: React.Ref<HTMLDivElement> }) => {
  const { fade, message, theme } = props;
  const [visible, setVisibility] = React.useState(false);
  let color = "#B4B6DA";

  if (theme === "warning") {
    color = "rgba(61, 83, 168, 1)";
  } else if (theme === "error") {
    color = "#FC4042";
  }

  React.useEffect(() => {
    const timer = setTimeout(() => setVisibility(fade), fadeDuration);
    return () => clearTimeout(timer);
  }, [fade]);

  return (
    <div className="u-posRelative" style={{ zIndex: 1001 }}>
      <FadeToggle
        pose={[`fixed${capitalize(position)}`, visible ? "visible" : "hidden"]}
      >
        <div style={{ backgroundColor: color }} ref={props.innerRef}>
          <div className="Page-container">
            <p className="u-paddingVmd u-marginVz u-textCenter u-textLarge">
              {message}
            </p>
          </div>
        </div>
      </FadeToggle>
    </div>
  );
};

const fixedStyle = { position: "fixed", left: 0, width: "100%" };

const FadeToggle = posed.div({
  visible: {
    opacity: 1,
    pointerEvents: "auto",
    height: "auto",
  },
  hidden: {
    height: 0,
    opacity: 0,
    pointerEvents: "none",
  },
  fixedBottom: {
    ...fixedStyle,
    bottom: 0,
  },
  fixedTop: {
    ...fixedStyle,
    top: 0,
  },
});

Notification.defaultProps = {
  theme: "success",
};

export default forwardRef<HTMLDivElement, Props>((props, ref) => (
  <Notification {...props} innerRef={ref} />
));
