import React, { ReactElement, ReactNode, useEffect, useRef } from 'react'
import { DropdownContext } from './context'
import './dropdown.less'

interface DropdownItemProps {
  trigger: ReactElement
  content: ReactNode
  index: number
  onOpen?: () => void
  onClose?: () => void
}

export const DropdownItem = ({ trigger, content, index, onOpen, onClose }: DropdownItemProps) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const triggerRef = useRef<HTMLDivElement>(null)
  const { openDropdownIndex, setOpenDropdownIndex, setIsAnyDropdownOpen } =
    React.useContext(DropdownContext)
  const [isOpen, setIsOpen] = React.useState(openDropdownIndex === index ? true : false)

  const handleOpen = () => {
    if (!isOpen) {
      setOpenDropdownIndex(index)
      setIsOpen(true)
      setIsAnyDropdownOpen(true)
    }
  }

  const handleMouseLeave = () => {
    setOpenDropdownIndex(null)
    if (isOpen) {
      setIsOpen(false)
      setIsAnyDropdownOpen(false)
    }
  }

  useEffect(() => {
    if (openDropdownIndex !== index && isOpen) {
      setIsOpen(false)
    }
  }, [openDropdownIndex])

  useEffect(() => {
    const handleMouseLeaveContent = (event: MouseEvent) => {
      if (contentRef.current && !contentRef.current.contains(event.target as Node)) {
        setOpenDropdownIndex(null)
        setIsOpen(false)
        setIsAnyDropdownOpen(false)
      }
    }

    contentRef.current?.addEventListener('mouseleave', handleMouseLeaveContent)

    return () => {
      contentRef.current?.removeEventListener('mouseleave', handleMouseLeaveContent)
    }
  }, [contentRef.current])

  const handleTriggerRef = (node: HTMLDivElement) => {
    // @ts-ignore
    triggerRef.current = node
  }

  const renderPseudoElement = () => {
    return <div className="dropdown__pseudo-element" />
  }

  return (
    <div className={`dropdown ${isOpen ? 'dropdown--open' : ''}`}>
      {React.cloneElement(trigger, {
        onClick: handleOpen,
        onMouseOver: handleOpen,
        ref: handleTriggerRef,
      })}
      <div className="dropdown__content-wrapper">
        <div ref={contentRef} onMouseLeave={handleMouseLeave} className="dropdown__content">
          {content}
          {renderPseudoElement()}
        </div>
      </div>
    </div>
  )
}

DropdownItem.displayName = 'DropdownItem'
