import React from 'react'
import { DesktopNavigation } from './DesktopNavigation'
import MobileNavigation from './MobileNavigation'
import { NavbarCategoryWrapperProps } from './NavbarCategoryWrapper'
import { FeaturedCard } from './NavigationFeaturedCard'

interface LabelObject {
  featuredCard: FeaturedCard
  label: {
    label: string
  }
  submenuList: NavbarCategoryWrapperProps['submenuList']
}

interface LabelFlatObject {
  labelFlat: string
  href: string
}

type LinkObject = LabelObject | LabelFlatObject

export interface NavbarProps {
  links: LinkObject[]
  style?: React.CSSProperties
  isGlobalAnnouncement?: boolean
  isCampaign?: boolean
}
// NavbarProps takes style as a prop, because sometimes over the navbar there is additional "Global Annoucement" component displayed so we need to push down the navbar
export const Navbar = ({ links, style, isGlobalAnnouncement, isCampaign }: NavbarProps) => {
  return (
    <>
      <div className="navbar-desktop" style={style}>
        <DesktopNavigation
          links={links}
          isGlobalAnnouncement={isGlobalAnnouncement}
          isCampaign={isCampaign}
        />
      </div>
      <div className="navbar-mobile" style={style}>
        <MobileNavigation links={links} isCampaign={isCampaign} />
      </div>
    </>
  )
}
